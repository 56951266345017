import { AccessProfile, AccessProfileResponse } from 'types/accessProfile';
import ApiError from 'types/ApiError';
import { METHOD_GET_ACCESS_PROFILES } from './constants';
import { parseAccessProfile } from './helpers/parsePortalResponseHelper';
import requestPortal from './portal';
import { isApiError } from './utils/isApiError';

const getAccessProfiles = async (companyId: number): Promise<Array<AccessProfile> | ApiError> => {
  const params = {
    companyId,
    activeFL: true,
  };

  const response = await requestPortal<AccessProfileResponse>(METHOD_GET_ACCESS_PROFILES, params);

  if (isApiError(response)) {
    return response as ApiError;
  }

  const accessProfileResponse = response as AccessProfileResponse[];
  const accessProfiles: AccessProfile[] = accessProfileResponse.map((apiResponseItem) =>
    parseAccessProfile(apiResponseItem),
  );

  return accessProfiles;
};

export default getAccessProfiles;
