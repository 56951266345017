export type AlertType = 'success' | 'error' | 'inputFieldRequired' | 'defaultError';
type AlertContent = { title: string; description: string };
type PostMessageBody = Partial<AlertContent> & {
  action: 'alert';
  type: AlertType;
};

const postMessage = (type: AlertType, content?: AlertContent) => {
  const data = {
    action: 'alert',
    type: type,
  } as PostMessageBody;
  if (content) {
    const { description, title } = content;
    data.title = title;
    data.description = description;
  }
  window.parent.postMessage(
    {
      ...data,
    },
    process.env.REACT_APP_PARENT_HOST!,
  );
};

const useAlert = () => {
  const success = (title: string, description: string) => {
    postMessage('success', {
      title,
      description,
    });
  };
  const error = (title: string, description: string) => {
    postMessage('error', {
      title,
      description,
    });
  };
  const inputFieldRequired = () => {
    postMessage('inputFieldRequired');
  };
  const defaultError = () => {
    postMessage('defaultError');
  };
  return {
    success,
    error,
    inputFieldRequired,
    defaultError,
  };
};

export default useAlert;
