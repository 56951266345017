import useAlert from 'hooks/useAlert';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import getAccessProfiles from 'services/accessProfiles';
import getApprovalGroup from 'services/approvalGroups';
import getDepartments from 'services/departments';
import { ERROR_PORTAL_EMPTY_RESPONSE } from 'services/errors/constants';
import getRoles from 'services/roles';
import { hasApiError, isApiError } from 'services/utils/isApiError';
import { AccessProfile } from 'types/accessProfile';
import { ApprovalGroup } from 'types/approvalGroup';
import { Department } from 'types/department';
import { Role } from 'types/role';

type JobFunctionContextDataType = {
  approvalGroup: ApprovalGroup[];
  accessProfiles: AccessProfile[];
  departments: Department[];
  roles: Role[];
  companyId?: number;
  errorJobFunctionASyncServices: boolean;
  loadingJobFunctions: boolean;
};

const JobFunctionContext = createContext({} as JobFunctionContextDataType);

const JobFuntionProvider = ({ children }: PropsWithChildren) => {
  const alert = useAlert();
  const [loadingJobFunctions, setLoadingJobFunctions] = useState(true);
  const [approvalGroup, setApprovalGroup] = useState<ApprovalGroup[]>([]);
  const [accessProfiles, setAccessProfiles] = useState<AccessProfile[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [errorJobFunctionASyncServices, setErrorJobFunctionASyncServices] = useState<boolean>(false);
  const { companyId, userId } = useParams();
  const validCompanyIdNumber = useMemo(() => Number(companyId), [companyId]);
  const validUserId = useMemo(() => Number(userId), [userId]);

  const getJobFuntions = async (_companyId: number, _userId: number) => {
    const responseDepartments = await getDepartments(_companyId, _userId);
    const responseApprovalGroup = await getApprovalGroup(_companyId);
    const responseRoles = await getRoles(_companyId, _userId);
    const responseAccessProfiles = await getAccessProfiles(_companyId);
    const apiError = hasApiError([responseApprovalGroup, responseAccessProfiles, responseDepartments, responseRoles]);
    if (apiError) {
      if (apiError?.status !== ERROR_PORTAL_EMPTY_RESPONSE) {
        setErrorJobFunctionASyncServices(true);
        setLoadingJobFunctions(false);
        return;
      }
    }
    if (!isApiError(responseApprovalGroup)) {
      setApprovalGroup(responseApprovalGroup as ApprovalGroup[]);
    }
    if (!isApiError(responseAccessProfiles)) {
      setAccessProfiles(responseAccessProfiles as AccessProfile[]);
    }
    if (!isApiError(responseDepartments)) {
      setDepartments(responseDepartments as Department[]);
    }
    if (!isApiError(responseRoles)) {
      setRoles(responseRoles as Role[]);
    }
    setLoadingJobFunctions(false);
  };

  useEffect(() => {
    if (!isNaN(validCompanyIdNumber) && !isNaN(validUserId)) {
      getJobFuntions(validCompanyIdNumber, validUserId);
    } else {
      setErrorJobFunctionASyncServices(true);
    }
  }, []);

  useEffect(() => {
    if (errorJobFunctionASyncServices) {
      alert.defaultError();
    }
  }, [errorJobFunctionASyncServices, alert]);

  return (
    <JobFunctionContext.Provider
      value={{
        approvalGroup,
        accessProfiles,
        departments,
        roles,
        companyId: Number(companyId),
        errorJobFunctionASyncServices,
        loadingJobFunctions,
      }}
    >
      {children}
    </JobFunctionContext.Provider>
  );
};

function useJobFunctionContext() {
  const context = useContext(JobFunctionContext);
  if (context === undefined) {
    throw new Error('JobFunctionContext can`t be initialized');
  }
  return context;
}

export { JobFuntionProvider, useJobFunctionContext, JobFunctionContextDataType };
export default JobFunctionContext;
