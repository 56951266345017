import { parseRole } from 'services/helpers/parsePortalResponseHelper';
import ApiError from 'types/ApiError';
import { Role, RoleResponse } from 'types/role';
import { METHOD_GET_OFFICES } from './constants';
import requestPortal from './portal';
import { isApiError } from './utils/isApiError';

const getRoles = async (companyId: number, userId: number): Promise<Array<Role> | ApiError> => {
  const params = {
    companyId,
    userId,
    activeFL: true,
  };
  const response = await requestPortal<RoleResponse>(METHOD_GET_OFFICES, params);

  if (isApiError(response)) {
    return response as ApiError;
  }

  const roleResponse = response as RoleResponse[];
  const roles: Role[] = roleResponse.map((apiResponseItem) => parseRole(apiResponseItem));

  return roles;
};

export default getRoles;
