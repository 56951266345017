import ApiError from 'types/ApiError';
import { Department, DepartmentResponse } from 'types/department';
import { METHOD_GET_DEPARTMENTS } from './constants';
import { parseDepartment } from './helpers/parsePortalResponseHelper';
import requestPortal from './portal';
import { isApiError } from './utils/isApiError';

const getDepartments = async (companyId: number, userId: number): Promise<Array<Department> | ApiError> => {
  const params = {
    companyId,
    hasSelectedCompany: true,
    userId,
    activeFL: true,
    todos: true,
  };

  const response = await requestPortal<DepartmentResponse>(METHOD_GET_DEPARTMENTS, params);
  if (isApiError(response)) {
    return response as ApiError;
  }

  const departmentResponse = response as DepartmentResponse[];
  const departments: Department[] = departmentResponse.map((apiResponseItem) => parseDepartment(apiResponseItem));

  return departments;
};

export default getDepartments;
