import Loading from 'components/UiComponents/Loading';
import { JobFuntionProvider } from 'contexts/JobFunctionContext';
import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const CollaboratorForm = lazy(() => import('components/InviteForm'));
const AppRoutes = () => {
  return (
    <Suspense fallback={<Loading fullscreen />}>
      <BrowserRouter>
        <Routes>
          <Route
            path="invite-list/:companyId/:userId"
            element={
              <JobFuntionProvider>
                <CollaboratorForm />
              </JobFuntionProvider>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutes;
