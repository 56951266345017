import axios from 'axios';
import getToken from 'utils/getToken';

const getInstance = () => {
  const token = getToken();

  return axios.create({
    baseURL: process.env.REACT_APP_WSV_HOST,
    headers: {
      'Content-Type': 'application/json',
      token,
    },
  });
};

const instance = getInstance();

export default instance;
