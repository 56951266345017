import ApiError from 'types/ApiError';
import { ApprovalGroup, ApprovalGroupResponse } from 'types/approvalGroup';
import { METHOD_GET_APPROVAL_GROUPS } from './constants';
import { parseApprovalGroup } from './helpers/parsePortalResponseHelper';
import requestPortal from './portal';
import { isApiError } from './utils/isApiError';

const getApprovalGroups = async (companyId: number): Promise<Array<ApprovalGroup> | ApiError> => {
  const params = {
    companyId,
    activeFL: true,
  };

  const response = await requestPortal<ApprovalGroupResponse>(METHOD_GET_APPROVAL_GROUPS, params);

  if (isApiError(response)) {
    return response as ApiError;
  }

  const accessProfileResponse = response as ApprovalGroupResponse[];
  const accessProfiles: ApprovalGroup[] = accessProfileResponse.map((apiResponseItem) =>
    parseApprovalGroup(apiResponseItem),
  );

  return accessProfiles;
};

export default getApprovalGroups;
