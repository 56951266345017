import { AccessProfile, AccessProfileResponse } from 'types/accessProfile';
import { ApprovalGroup, ApprovalGroupResponse } from 'types/approvalGroup';
import { CostCenter, CostCenterResponse } from 'types/costCenter';
import { Department, DepartmentResponse } from 'types/department';
import { Role, RoleResponse } from 'types/role';

export const parseRole = (apiResponseItem: RoleResponse): Role => {
  return {
    roleId: apiResponseItem.idcargo,
    companyId: apiResponseItem.idempresa,
    roleDescription: apiResponseItem.desccargo,
    activeFL: apiResponseItem.flativo,
  };
};

export const parseDepartment = (apiResponseItem: DepartmentResponse): Department => {
  return {
    departmentDescription: apiResponseItem.descdepartamento,
    companyId: apiResponseItem.idempresa,
    parentDepartmentId: apiResponseItem.iddepartamentopai,
    costCenterId: apiResponseItem.idcentrodecusto,
    departmentCode: apiResponseItem.codigodepartamento,
    departmentId: apiResponseItem.iddepartamento,
    activeFL: apiResponseItem.flativo,
  };
};

export const parseCostCenter = (apiResponseItem: CostCenterResponse): CostCenter => {
  return {
    ledgetAccountCategoryId: apiResponseItem.idcategoriacontacontabil,
    parentCostCenterId: apiResponseItem.idcentrodecustopai,
    companyId: apiResponseItem.idempresa,
    costCenterId: apiResponseItem.idcentrodecusto,
    costCenterName: apiResponseItem.nomecentrodecusto,
    costCenterDescription: apiResponseItem.desccentrodecusto,
    activeFL: apiResponseItem.flativo,
  };
};

export const parseAccessProfile = (apiResponseItem: AccessProfileResponse): AccessProfile => {
  return {
    profileAccessDescription: apiResponseItem.descperfilacesso,
    flViewUsersApproveGroup: apiResponseItem.flvisualizausuariosgrupoaprovacao,
    flViewOnlyOwnUsersApproveGroup: apiResponseItem.flvisualizasomentepropriousuario,
    flAllowRevoke: apiResponseItem.flpermiterevogacao,
    accessProfileId: apiResponseItem.idperfilacesso,
    flViewCostCenterUsersApproveGroup: apiResponseItem.flvisualizausuarioscentrodecusto,
    flAllowAprove: apiResponseItem.flpermiteaprovacao,
    flAllowMakePayment: apiResponseItem.flpermiterealizarpagamento,
    flSendExpensesAnotherUser: apiResponseItem.flpermitelancardespesaparaoutrousuario,
    flViewDeparmentUsers: apiResponseItem.flvisualizausuariosdepartamento,
    companyId: apiResponseItem.idempresa,
    flViewCompanyGroupUsers: apiResponseItem.flvisualizatodosusuariosgrupoempresa,
    flAllowViewExpernsesTime: apiResponseItem.flpermitevisualizarhorariodespesa,
    flViewCompanyGroupApproveGroupUsers: apiResponseItem.flvisualizausuariosgrupoaprovacaogrupoempresa,
    flViewAllCompanyUsers: apiResponseItem.flvisualizatodosusuariosempresa,
    flActive: apiResponseItem.flativo,
  };
};

export const parseApprovalGroup = (apiResponseItem: ApprovalGroupResponse): ApprovalGroup => {
  return {
    flLevelApprove: apiResponseItem.flaprovacaopornivel,
    flExpensesApprove: apiResponseItem.flaprovacaodespesas,
    flSendEmail: apiResponseItem.flenviaemail,
    flJourneysApprove: apiResponseItem.flaprovacaoviagens,
    approveGroupDescription: apiResponseItem.descgrupoaprovacao,
    flApproveAllApprovers: apiResponseItem.flobrigaaprovacaotodosaprovadores,
    flActive: apiResponseItem.flativo,
    approveGroupId: apiResponseItem.idgrupoaprovacao,
    flApproveAdvances: apiResponseItem.flaprovacaoadiantamentos,
  };
};
