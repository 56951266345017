import { CircularProgress, CircularProgressProps } from '@mui/material';
import { LoadingContainer } from './style';

const themes = {
  purple: 'secondary',
  green: 'success',
  gray: 'inherit',
} as CircularProgressProps;

export type LoadingProps = {
  theme?: string;
  fullscreen?: boolean;
};

const Loading = ({ theme, fullscreen }: LoadingProps) => {
  return (
    <LoadingContainer data-testid={'loading-container'} fullscreen={fullscreen}>
      {!theme ? (
        <CircularProgress data-testid={`circular-progress`} />
      ) : (
        <CircularProgress data-testid={`circular-progress-${theme}`} color={themes[theme as keyof typeof themes]} />
      )}
    </LoadingContainer>
  );
};

export default Loading;
