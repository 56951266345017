import ApiError from 'types/ApiError';

export const isApiError = (object: any | ApiError) => {
  if (!object) return true;
  return 'status' in object || 'message' in object;
};

export const hasApiError = (array: Array<any>) => {
  const apiError = array.find((item) => isApiError(item));
  if (apiError === null) return { status: 0, message: 'default error' } as ApiError;
  return apiError as ApiError | undefined;
};
