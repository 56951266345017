import { AxiosError } from 'axios';
import { RequestPortalMethods } from 'services/types';
import { ERROR_PORTAL_EMPTY_RESPONSE } from './constants';

class NotFoundDataPortalError extends AxiosError {
  constructor(method: RequestPortalMethods) {
    super(method + ' not found', String(ERROR_PORTAL_EMPTY_RESPONSE));
  }
}

export default NotFoundDataPortalError;
